<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>{{ title }}</strong>
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CInput
            id="username"
            description="Nombre de usuario"
            label="Nombre de usuario"
            horizontal
            :disabled="information"
            :value="user.username"
            maxlength="30"
          />
          <CInput
            id="password"
            type="password"
            description="Contraseña"
            label="Contraseña"
            horizontal
            :disabled="information"
            :value="user.password"
            v-if="!information"
          />
          <CInput
            id="repeat-password"
            type="password"
            description="Confirmar contraseña"
            label="Confirmar contraseña"
            horizontal
            :disabled="information"
            :value="user.password"
            v-if="!information"
          />
          <CInput
            id="name"
            description="Nombre"
            label="Nombre"
            horizontal
            :disabled="information"
            :value="user.name"
            maxlength="30"
          />
          <CInput
            id="surname"
            description="Apellidos"
            label="Apellidos"
            horizontal
            :disabled="information"
            :value="user.surname"
            maxlength="20"
          />

          <CInput
            id="email"
            type="email"
            description="E-mail"
            label="E-mail"
            horizontal
            :disabled="information"
            :value="user.email"
            maxlength="50"
          />

          <CRow class="form-input-row">
            <CCol lg="3" md="3">Género</CCol>
            <CCol lg="9" md="9" class="select-row">
              <select id="gender" v-if="!information">
                <option selected="selected" value="none-gender">
                  Elige género
                </option>
                <option value="0">Hombre</option>
                <option value="1">Mujer</option>
                <option value="2">Otros</option>
              </select>
              <CInput
                v-else
                id="gender"
                description="Género"
                :disabled="information"
                :value="userGenderValue()"
              />
            </CCol>
          </CRow>

          <CInput
            id="weight"
            type="number"
            description="Peso (kilogramos)"
            label="Peso"
            horizontal
            :disabled="information"
            :value="user.weight"
            min="0"
            max="99999"
          />
          <CInput
            id="height"
            type="number"
            description="Altura (metros)"
            label="Altura"
            horizontal
            :disabled="information"
            :value="user.height"
            min="0"
            max="99999"
          />
          <CInput
            id="min-limit"
            type="number"
            min="0"
            max="99999"
            description="Limite mínimo"
            label="Limite mínimo"
            horizontal
            :disabled="information"
            :value="user.min_limit"
          />
          <CInput
            id="max-limit"
            type="number"
            min="0"
            max="99999"
            description="Limite máximo"
            label="Limite máximo"
            horizontal
            :disabled="information"
            :value="user.max_limit"
          />
          <CTextarea
            id="description"
            description="Descripción"
            label="Descripción"
            horizontal
            :disabled="information"
            :value="user.description"
            maxlength="400"
          />
          <CInput
            id="birthdate"
            type="date"
            description="Fecha de nacimiento"
            label="Fecha de nacimiento"
            horizontal
            :disabled="information"
            :value="user.birthdate"
          />
        </CForm>
        <div class="d-flex justify-content-center">
          <CButton
            class="btn btn-secondary"
            style="margin-right: 1em"
            @click="back()"
            >{{ backButtonText }}</CButton
          >
          <CButton
            class="btn btn-success active"
            @click="addUser()"
            v-if="!information"
            >{{ submitButtonText }}</CButton
          >
        </div>
        <CRow class="justify-content-center">
          <CCol md="9" class="p-4">
            <CAlert
              color="danger"
              dismissible
              :show.sync="contAlertParams"
              close-button
            >
              <strong>AVISO</strong>
              {{ errorText }}
              <CProgress
                :max="10"
                :value="contAlertParams"
                height="3px"
                color="danger"
                animated
              />
            </CAlert>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
import { decodePayload, checkUser } from "../../utils/utils";
/**
 * Función para conseguir los datos de un usuario dado por el id.
 *
 * @returns {Promise<object>} Promesa que al resolverse nos da los datos del usuario.
 */
async function getUserData(id) {
  let data;
  const token = localStorage.token;
  await axios
    .get(config.BACK_IP + config.BACK_IP_USER + "/" + id, {
      headers: {
        token: token,
      },
    })
    .then((result) => {
      data = result.data;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/pages/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}
function getDataReadyToCreate() {
  const { id } = decodePayload(localStorage.token);
  let id_fisio = id;
  let username = document.getElementById("username").value;
  let password = document.getElementById("password").value;
  let name = document.getElementById("name").value;
  let surname = document.getElementById("surname").value;
  let email = document.getElementById("email").value;
  let gender = document.getElementById("gender").value;
  let weight = document.getElementById("weight").value;
  let height = document.getElementById("height").value;
  let min_limit = document.getElementById("min-limit").value;
  let max_limit = document.getElementById("max-limit").value;
  let description = document.getElementById("description").value;
  let birthdate = document.getElementById("birthdate").value;
  let new_user = {
    id_fisio,
    username,
    password,
    name,
    surname,
    email,
    gender,
    weight,
    height,
    min_limit,
    max_limit,
    description,
    birthdate,
  };
  return new_user;
}
export default {
  name: "addUser",
  data: () => ({
    title: "Nuevo Usuario",
    user: {},
    id: null,
    information: false,
    submitButtonText: "Crear",
    backButtonText: "Volver",
    contAlertParams: 0,
    minLimit: null,
    maxLimit: null,
    errorText: "Campos vacios o erroneos.",
  }),
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
    receiveData() {
      this.checkId();
      this.getUser();
    },
    checkId() {
      this.id = this.$route.params.id;
      if (this.$route.params.id) {
        this.information = true;
      } else {
        this.information = false;
      }
    },
    getUser() {
      if (!this.id) return;
      this.title = "Información Usuario";
      this.submitButtonText = "Editar";
      let data = getUserData(this.id);
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.user = result.user;
        this.user.birthdate = this.changeDateFormatUser(this.user.birthdate);
      });
    },
    addUser() {
      let token = localStorage.token;
      if (this.checkInputs() == false) {
        return;
      }
      if (!this.id) {
        axios
          .post(config.BACK_IP + config.BACK_IP_USER, getDataReadyToCreate(), {
            headers: {
              token,
            },
          })
          .then(() => {
            this.$router.push("/user");
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.errorText = this.reciveError(error.response.data.code);
                this.showFailureParameters();
              }
              if (error.response.status == 401) {
                window.location = "#/pages/login";
              }
              if (error.response.status == 404) {
                window.location = "#/pages/404";
              }
              if (error.response.status == 409) {
                this.errorText = this.reciveError(error.response.data.code);
                this.showFailureParameters();
              }
              if (error.response.status == 500) {
                window.location = "#/500";
              }
            }
          });
        return;
      }
    },
    userGenderValue() {
      if (this.user.gender === 0) {
        return "Hombre";
      } else if (this.user.gender === 1) {
        return "Mujer";
      } else {
        return "Otros";
      }
    },
    checkInputs() {
      let correct = true;
      let userName = document.getElementById("username").value;
      let password = document.getElementById("password").value;
      let repeatPassWord = document.getElementById("repeat-password").value;
      let name = document.getElementById("name").value;
      let surname = document.getElementById("surname").value;
      let email = document.getElementById("email").value;
      let gender = document.getElementById("gender").value;
      let weight = document.getElementById("weight").value;
      let height = document.getElementById("height").value;
      let minLimit = document.getElementById("min-limit").value;
      let maxLimit = document.getElementById("max-limit").value;
      let description = document.getElementById("description").value;
      let birthdate = document.getElementById("birthdate").value;

      if (birthdate == "") {
        this.errorText = "Introduzca la fecha de nacimiento.";
        this.showFailureParameters();
        correct = false;
      }
      if (description == "") {
        this.errorText = "Introduzca una descripción.";
        this.showFailureParameters();
        correct = false;
      }
      if (description.length > 400) {
        this.errorText = "La descripción es demasiado larga.";
        this.showFailureParameters();
        correct = false;
      }
      if (height == "" || parseInt(height) < 0) {
        this.errorText = "Introduzca la altura.";
        this.showFailureParameters();
        correct = false;
      }
      if (weight == "" || parseInt(weight) < 0) {
        this.errorText = "Introduzca el peso.";
        this.showFailureParameters();
        correct = false;
      }
      if (minLimit > 99999) {
        this.errorText = "El limite mínimo es demasiado alto.";
        this.showFailureParameters();
        correct = false;
      }
      if (maxLimit > 99999) {
        this.errorText = "El limite máximo es demasiado alto.";
        this.showFailureParameters();
        correct = false;
      }
      if (minLimit == maxLimit) {
        this.errorText =
          "El limite mínimo no puede ser igual que el limite máximo.";
        this.showFailureParameters();
        correct = false;
      }
      if (parseInt(minLimit) > parseInt(maxLimit)) {
        this.errorText =
          "El limite mínimo no puede ser mayor que el limite máximo.";
        this.showFailureParameters();
        correct = false;
      }
      if (minLimit == "") {
        this.errorText = "Introduzca el limite mínimo.";
        this.showFailureParameters();
        correct = false;
      }
      if (maxLimit == "") {
        this.errorText = "Introduzca el limite máximo.";
        this.showFailureParameters();
        correct = false;
      }
      if (parseInt(maxLimit) < 0 || parseInt(minLimit) < 0) {
        this.errorText = "No se pueden introducir valores negativos.";
        this.showFailureParameters();
        correct = false;
      }
      if (gender === "none-gender") {
        this.errorText = "Seleccione un género.";
        this.showFailureParameters();
        correct = false;
      }
      if (email == "") {
        this.errorText = "Introduzca el email.";
        this.showFailureParameters();
        correct = false;
      }
      if (surname == "") {
        this.errorText = "Introduzca el apellido.";
        this.showFailureParameters();
        correct = false;
      }
      if (surname.length > 20) {
        this.errorText = "El apellido es demasiado largo.";
        this.showFailureParameters();
        correct = false;
      }
      if (name == "") {
        this.errorText = "Introduzca el nombre.";
        this.showFailureParameters();
        correct = false;
      }
      if (name.length > 30) {
        this.errorText = "El nombre es demasiado largo.";
        this.showFailureParameters();
        correct = false;
      }
      if (password < 8) {
        this.errorText = "La contraseña tiene que tener mínimo 8 caracteres.";
        this.showFailureParameters();
        correct = false;
      }
      if (password.length > 30) {
        this.errorText = "La contraseña es demasiado larga.";
        this.showFailureParameters();
        correct = false;
      }
      if (password !== repeatPassWord) {
        this.errorText = "Las contraseñas deben ser iguales.";
        this.showFailureParameters();
        correct = false;
      }
      if (userName == "") {
        this.errorText = "Introduzca el nombre de usuario.";
        this.showFailureParameters();
        correct = false;
      }
      if (userName.split(" ").length > 2) {
        this.errorText = "El nombre de usuario no puede contener espacios.";
        this.showFailureParameters();
        correct = false;
      }
      if (userName.length > 30) {
        this.errorText = "El nombre de usuario es demasiado largo.";
        this.showFailureParameters();
        correct = false;
      }
      return correct;
    },
    reciveError(cod) {
      let textError;
      switch (cod) {
        case "001":
          textError = "El usuario no puede tener espacios en blanco";
          break;
        case "002":
          textError = "Ese usuario ya existe";
          break;
        case "003":
          textError = "La contraseña es incorrecta";
          break;
        case "004":
          textError = "El email es incorrecto";
          break;
        case "005":
          textError = "Ese email ya existe";
          break;
        case "006":
          textError = "El género es incorrecto";
          break;
        case "007":
          textError = "El peso es incorrecto";
          break;
        case "008":
          textError = "La altura es incorrecto";
          break;
        case "009":
          textError = "La fecha es incorrecto";
          break;
      }
      return textError;
    },
    showFailureParameters() {
      this.contAlertParams = 10;
    },

    hasOneDigit(val) {
      return String(Math.abs(val)).charAt(0) == val;
    },
    /**
     * Función para cambiar el formato de la fecha
     *
     * @returns {number} La fecha con el formato cambiado a "año-mes-dia"
     */
    changeDateFormatUser(dateWithoutFormat) {
      if (dateWithoutFormat == "") return dateWithoutFormat;
      let date = new Date(Date.parse(dateWithoutFormat));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (this.hasOneDigit(day) && this.hasOneDigit(month)) {
        return year + "-" + "0" + month + "-" + "0" + +day;
      } else if (this.hasOneDigit(day)) {
        return year + "-" + month + "-" + "0" + day;
      } else if (this.hasOneDigit(month)) {
        return year + "-" + "0" + month + "-" + day;
      } else {
        return year + "-" + month + "-" + day;
      }
    },
    back() {
      this.$router.push("/user/");
    },
  },
};
</script>
<style>
textarea.form-control {
  height: 200px;
}
</style>